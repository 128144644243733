import Posts from './pages/Login.vue'

class Login {
    constructor(data) {
        new Vue({
            el: '#login-page',
            components: {},
            data: {},
            render: h => h(Posts, {
                props: {
                    pageData: data.pageData
                }
            })
        });
    }
}

window.Login = Login