<template>
    <div class="cp-grid cp-grid--hor cp-grid--root  cp-login cp-login cp-login--signin" id="cp-login">
        <div class="cp-grid__item cp-grid__item--fluid cp-grid cp-grid--hor" :style="headerStyles">
            <div class="cp-grid__item cp-grid__item--fluid cp-login__wrapper">
                <div class="cp-login__container">
                    <div class="cp-login__logo">
                        <a :href="this.homePage">
                            <img :src="this.logo" class="h-6 login-logo" alt="">
                        </a>
                    </div>
                    <div class="cp-login__signin">
                        <div class="cp-login__head">
                            <h3 class="cp-login__title">{{ 'login' | translate }}</h3>
                        </div>
                        <form class="cp-form" @submit.prevent="submit" novalidate="novalidate">
                            <div class="form-group">
                                <div class="input-group">
                                    <input type="email" v-model="form.email" class="form-control" required autocomplete="email" autofocus placeholder="email@example.com">
                                </div>
                            </div>
                            <div class="form-group" v-if="usePassword">
                                <div class="input-group">
                                    <input type="password" v-model="form.password" ref="password" class="form-control" required autocomplete="current-password" :placeholder="'password' | translate">
                                </div>
                            </div>

                            <div class="cp-notes" v-if="accounts.length">
                                <div class="cp-notes__items">
                                    <div v-for="account in accounts" class="cp-notes__item">
                                        <div class="cp-notes__media">
                                            <img v-if="account.logo" :src="account.logo" alt="image">
                                            <span v-else class="cp-notes__icon">
                                                <i v-if="selectedAccount === account.id" class="cp-font-success flaticon-paper-plane-1"></i>
                                                <i v-else class="cp-font-info flaticon2-user"></i>
                                            </span>
                                        </div>
                                        <div class="cp-notes__content">
                                            <div class="cp-notes__section">
                                                <div class="cp-notes__info">
                                                    <a class="cp-notes__title">
                                                        {{ account.name }}
                                                    </a>
                                                </div>
                                                <div class="cp-notes__dropdown">
                                                    <button
                                                        type="button"
                                                        ref="chooseAccountButton"
                                                        :disabled="processing"
                                                        :class="[selectedAccount === account.id ? 'btn-outline-success' : 'btn-outline-brand']"
                                                        class="btn btn-elevate btn-icon"
                                                        @click="selectedAccount = account.id">

                                                        <template v-if="selectedAccount === account.id">
                                                            <i class="fa fa-check"></i>
                                                        </template>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row cp-login__extra">
                                <div class="col cp-align-right">
                                    <a :href="this.homePage" id="cp-login_forgot" class="cp-login__link">{{ 'home' | translate }}</a>
                                </div>
                            </div>

                            <div class="cp-login__actions">
                                <button id="cp-login_signin_submit" type="button" class="btn btn-brand btn-elevate cp-login__btn-primary" @click.prevent="submit" ref="submitButton" :disabled="processing || !canSubmit">
                                    <template v-if="processing">
                                        <i class="fa fa-spin fa-spinner"></i>
                                        {{ 'processing' | translate }}
                                    </template>
                                    <template v-else>
                                        <i class="la la-check"></i>
                                        {{ 'login' | translate }}
                                    </template>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            pageData: Object
        },
        name: 'login',
        data() {
            return {
                homePage: this.pageData.homePage,
                appName: this.pageData.appName,
                backgroundImage: this.pageData.backgroundImage,
                logo: this.pageData.logo,
                usePassword: false,
                accounts: [],
                selectedAccount: null,
                processing: false,
                errorClass: 'form-control is-invalid',
                form: {
                    email: '',
                    password: null
                }
            }
        },
        beforeMount() {
        },
        mounted() {
        },
        computed: {
            headerStyles () {
                return {
                    'background-image': 'url(' + this.backgroundImage + ')'
                }
            },
            /**
             * Returns true if inputs are invalid.  Disables Submit button
             *
             * @return {boolean}
             */
            canSubmit: function() {
                if (
                    this.form.email === '' ||
                    this.form.email === 'undefined' ||
                    this.form.email === null
                ) {
                    return false
                }


                if (this.usePassword) {
                    if (
                        this.form.password === '' ||
                        this.form.password === 'undefined' ||
                        this.form.password === null
                    ) {
                        return false
                    }
                }

                if (
                    this.accounts.length > 0 &&
                    this.selectedAccount === null
                ) {
                    return false
                }

                return true
            }
        },
        methods: {
            /**
             * Handles submit
             */
            submit() {
                this.processing = true

                let url = `/login`
                let postData = {
                    'id': this.selectedAccount,
                    'email': this.form.email,
                    'password': this.form.password,
                    'usePassword': this.usePassword
                }

                axios
                    .post(url, postData)
                    .then(response => {
                        if (response.data.success && response.data.url) {
                            window.location.href = response.data.url
                        } else {
                            this.selectedAccount = response.data.id
                            this.form.email = response.data.email
                            this.usePassword = response.data.usePassword
                            this.accounts = response.data.accounts
                        }
                    })
                    .catch(error => {
                        EventBus.$emit('alert:trigger', {
                            content: error.response.data.error,
                            type: 'danger',
                            dismissable: true
                        })
                    })
                    .finally(() => {
                        this.processing = false;
                    })
            },
            /**
             * Focuses the cursor on the Title input field
             **/
            focusTitleField() {
                this.$nextTick(() => {
                    this.$refs.password.focus()
                })
            }
        }
    }
</script>

<style lang="scss">
    .cp-login.cp-login {
        background-size: cover;
        background-repeat: no-repeat;
        background-color: white;
    }
    .cp-login.cp-login .cp-login__wrapper {
        padding: 5% 10px 1% 10px;
        margin: 0 auto 1rem auto;
        overflow: hidden;
    }
    .cp-login.cp-login .cp-login__wrapper .cp-login__container {
        width: 430px;
        margin: 0 auto;
        background-color: white;
        padding-top: 0;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 5px;
    }
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-login__logo {
        text-align: center;
        margin: 0 auto 4rem auto;
    }
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-login__head {
        margin-top: 1rem;
        margin-bottom: 3rem;
    }
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-login__head .cp-login__title {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 500;
        color: #6c7293;
    }
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-login__head .cp-login__desc {
        margin-top: 1.5rem;
        text-align: center;
        font-size: 1.1rem;
        font-weight: 400;
        color: #a7abc3;
    }
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-form {
        margin: 0 auto;
    }
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-form .input-group {
        padding: 0;
        margin: 0 auto;
    }
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-form .form-control {
        height: 46px;
        border: none;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-top: 0.5rem;
        background: rgba(235, 237, 242, 0.4);
    }
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-form .form-control::-moz-placeholder {
        color: #a7abc3;
        opacity: 1;
    }
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-form .form-control:-ms-input-placeholder {
        color: #a7abc3;
    }
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-form .form-control::-webkit-input-placeholder {
        color: #a7abc3;
    }
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-form .form-control.is-valid + .valid-feedback,
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-form .form-control.is-invalid + .invalid-feedback {
        font-weight: 500;
        font-size: 0.9rem;
        padding-left: 1.6rem;
    }
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-form .cp-login__extra {
        margin-top: 30px;
        margin-bottom: 15px;
        color: #a7abc3;
        font-size: 1rem;
    }
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-form .cp-login__extra .cp-checkbox {
        font-size: 1rem;
    }
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-form .cp-login__extra .cp-login__link {
        font-size: 1rem;
        color: #a7abc3;
        -webkit-transition: color 0.3s ease;
        transition: color 0.3s ease;
    }
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-form .cp-login__extra .cp-login__link:hover {
        color: #5d78ff;
        -webkit-transition: color 0.3s ease;
        transition: color 0.3s ease;
    }
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-form .cp-login__actions {
        text-align: center;
        margin-top: 7%;
    }
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-form .cp-login__actions .cp-login__btn-secondary,
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-form .cp-login__actions .cp-login__btn-primary {
        height: 50px;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-login__account {
        text-align: center;
        margin-top: 2rem;
    }
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-login__account .cp-login__account-msg {
        font-size: 1rem;
        font-weight: 400;
        color: #a7abc3;
    }
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-login__account .cp-login__account-link {
        font-size: 1rem;
        font-weight: 500;
        color: #6c7293;
        -webkit-transition: color 0.3s ease;
        transition: color 0.3s ease;
    }
    .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-login__account .cp-login__account-link:hover {
        color: #5d78ff;
        -webkit-transition: color 0.3s ease;
        transition: color 0.3s ease;
    }

    .cp-login.cp-login.cp-login--signin .cp-login__signin {
        display: block;
    }

    .cp-login.cp-login.cp-login--signup .cp-login__signup {
        display: block;
    }

    .cp-login.cp-login.cp-login--forgot .cp-login__forgot {
        display: block;
    }

    .cp-login__divider {
        margin: 1rem 0 2rem 0; }
    .cp-login__divider:not(:first-child):not(:last-child) {
        font-weight: 400;
        color: #b5b2c3;
        font-size: 1rem; }

    .cp-login__options {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        max-width: 100%;
    }
    .cp-login__options > a {
        text-decoration: none;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .cp-login__options > a:not(:first-child):not(:last-child) {
        margin: 0 1.5rem;
    }
    .cp-login__options > a:not(:first-child):not(:last-child) {
        margin: 0 0.8rem;
    }

    @media (max-width: 1024px) {
        .cp-login.cp-login .cp-login__wrapper {
            padding-top: 5rem;
            width: 100%; }
        .cp-login.cp-login .cp-login__wrapper .cp-login__container {
            margin: 0 auto; }
        .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-login__account {
            margin-top: 1rem; }
    }

    @media (max-width: 768px) {
        .cp-login.cp-login .cp-login__wrapper {
            width: 100%; }
        .cp-login.cp-login .cp-login__wrapper .cp-login__container {
            width: 100%;
            margin: 0 auto; }
        .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-form {
            width: 100%;
            margin: 0 auto; }
        .cp-login.cp-login .cp-login__wrapper .cp-login__container .cp-login__account {
            margin-top: 1rem; }
    }
</style>
